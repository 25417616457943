.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

/*********** Custom Scroll Bar ****************/

.ez-custom-scroll::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
    background-color: #f5f5f5;
    border-radius: 10px;
}

.ez-custom-scroll::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: #f5f5f5;
}

.ez-custom-scroll::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 10px;
}

::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
    background-color: transparent;
    border-radius: 10px;
}

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 10px;
}

/*********** Custom Scroll Bar ****************/

/***********Hover Card Css **************/

.ez-card-glow:hover {
    box-shadow: 0px 6px 15px rgb(0 140 133 / 50%);
}

.ez-card-glow.active {
    box-shadow: 1px solid #008c85;
}

.carousel .carousel-status {
    position: absolute;
    bottom: 0;
    left: 50%;
}

.text-center {
    text-align: center;
}

.progressImage {
    border-radius: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 80%;
    height: 80%;
}

.workOrderInfo {
    position: relative;
    display: block;
    left: 88%;
    width: 85px;
    bottom: 30%;
    font-size: 12px;
    color: #b7b7b7;
    font-weight: bold;
}

.workOrderInfo h3 {
    margin: 0px;
    font-size: 18px;
    color: rgb(0, 140, 133);
}

/*********** For Rotaing any icon or span ***********/
@keyframes spin {
    0% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.rotateIcon {
    animation: 'spin 01s linear infinite';
}

.editor {
    box-sizing: border-box;
    border: 1px solid #ddd;
    cursor: text;
    padding: 16px;
    border-radius: 2px;
    margin-bottom: 2em;
    box-shadow: inset 0px 1px 8px -3px #ababab;
    background: #fefefe;
}

.editor :global(.public-DraftEditor-content) {
    min-height: 140px;
}

/************** Error Message ****************/
.error-message {
    color: #e73535;
    margin-right: 20px;
    margin-bottom: 5px;
    font-size: 0.75rem;
    margin-top: 8px;
}

.error-message::before {
    content: '⚠ ';
}
