.fc-event {
    margin-bottom: 2px;
    /* font-size: 1.2em;
    height: 1.6em; */
    cursor: pointer;
    overflow: hidden;
}

.fc-event:hover {
    opacity: 0.9;
}

.cluster {
    background-color: transparent;
    border: none;
}
