body {
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif; */
    /* font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

html {
    overflow-x: hidden;
}

html,
body,
#root,
#App {
    height: 100%;
    width: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.scroll-bar::-webkit-scrollbar {
    width: 4px;
}

.scroll-bar::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #d9d9d9;
}

.scroll-bar {
    overflow-y: overlay;
}

.pac-container {
    z-index: 99999999999;
}
