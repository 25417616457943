.Container {
    border: 1px solid transparent;
    cursor: pointer;
    border-radius: 4px;
    padding: 3px;
}

.Container:hover {
    border: 1px solid #999;
    visibility: visible;
}

.Icon {
    visibility: hidden;
}

.Container:hover > .Icon {
    visibility: visible;
}
