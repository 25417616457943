.Container {
    overflow: hidden;
    cursor: pointer;
    user-select: none;
    border-radius: 5px;
    position: relative;
}

.Slides {
    transition: left 500ms;
    position: absolute;
    height: 100%;
    border-radius: 5px;
}

.Rotates {
    transition: transform 500ms;
}

.BgColorFade {
    transition: background-color 200ms;
}
